<template>
  <div>
    <div class="block-container">
      <el-card class="block-card">
        <div class="box-card-gene-exp">
          <div class="box-card-item">
            <span class="box-card-item-icon">
              <font-awesome-icon icon="fa-solid fa-people-roof" />
            </span>
            <span> About</span>
          </div>
        </div>
        <!-- About-Info -->
        <div id="about-info" class="box-card-about-info">
          <div class="info-item">
            <span class="info-item-icon">
              <font-awesome-icon icon="fa-solid fa-bookmark" />
            </span>
            <span> About Earthquake Alerting Tool</span>
          </div>
          <div class="info-item-content">
            <span v-html="msg">
              {{ msg }}
            </span>
          </div>
          <div class="info-item">
            <span class="info-item-icon">
              <font-awesome-icon icon="fa-solid fa-bookmark" />
            </span>
            <span> About Our Team</span>
          </div>
          <div class="info-item-content">
            <span v-html="ourTeamMsg">
              {{ ourTeamMsg }}
            </span>
          </div>
          <div class="our-team-content">
            <!-- <el-carousel height="1500px" :interval="5000" arrow="always"> -->
            <el-carousel
              :height="carouselHeight"
              :interval="5000"
              arrow="always"
            >
              <el-carousel-item
                class="every-group-item"
                v-for="(list, index) in teamList"
                :key="index"
              >
                <div
                  class="person-item"
                  v-for="(personList, index2) in list"
                  :key="index2"
                >
                  <img
                    class="person-img"
                    :src="personList.img"
                    style="object-fit: fill"
                  />
                  <div class="angles-up">
                    <font-awesome-icon icon="fa-solid fa-angles-up" />
                  </div>
                  <div class="person-card">
                    <div class="person-title">
                      <p
                        style="
                          font-weight: bold;
                          font-size: 17px;
                          color: #223468;
                        "
                      >
                        <a
                          style="text-decoration: none; color: #223468"
                          :href="personList.link"
                          target="_blank"
                          >{{ personList.name }}</a
                        >
                      </p>
                    </div>
                    <div class="person-info">
                      <p>
                        <font-awesome-icon icon="fa-solid fa-user-tag" />
                        {{ personList.position }}
                      </p>
                      <p>
                        <font-awesome-icon icon="fa-solid fa-id-card" />
                        {{ personList.affiliation }}
                      </p>
                      <p>
                        <font-awesome-icon icon="fa-solid fa-envelope" />
                        {{ personList.email }}
                      </p>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="info-item">
            <span class="info-item-icon">
              <font-awesome-icon icon="fa-solid fa-clipboard-question" />
            </span>
            <span> Contact us</span>
          </div>
          <div class="info-item-content">
            <span v-html="contactMsg">
              {{ contactMsg }}
            </span>
          </div>
          <!-- <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
          <div class="info-item">
            <span class="info-item-icon">
              <font-awesome-icon icon="fa-solid fa-bookmark" />
            </span>
            <span> Global Visitors</span>
          </div>
          <div class="info-item-content">
            <div class="global-visitors">
              <a
                href="https://clustrmaps.com/site/1bpqx"
                title="Visit tracker"
                target="_blank"
                ><img
                  class="global-visitors-img"
                  src="//www.clustrmaps.com/map_v2.png?d=PktRdM8OKxPsNhr2pZO-GPBcle3pD60JWQn8eL80Z5I&cl=ffffff"
              /></a>
            </div>
          </div> -->
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import imgLuonanChen from "/public/img/about/about-LuonanChen.jpg";
import imgKazuyukiAihara from "/public/img/about/about-KazuyukiAihara.jpg";
import imgRuiLiu from "/public/img/about/about-RuiLiu.jpg";
import imgPeiChen from "/public/img/about/about-PeiChen.jpg";
import imgYuyanTong from "/public/img/about/about-YuyanTong.jpg";
import imgRenhaoHong from "/public/img/about/about-RenhaoHong.jpg";
import imgZeZhang from "/public/img/about/about-ZeZhang.jpg";

export default {
  name: "About",
  data() {
    return {
      msg: "Alerting imminent earthquakes is particularly challenging due to the high nonlinearity and nonstationarity of geodynamical phenomena. Based on spatiotemporal information transformation (STI) for high-dimensional real-time data, a model-free framework, i.e., real-time spatiotemporal information transformation learning (RSIT), is developed for extending the nonlinear and nonstationary time series. RSIT explores both the spatial and temporal dynamics of real-world data on the basis of a solid theoretical background in nonlinear dynamics and delay-embedding theory.",
      ourTeamMsg:
        "This tool is developed by: <a href='http://cemcs.cas.cn/sourcedb_cemcs_cas/zw/pi/202008/t20200823_5670080.html' target='_blank'>Luonan Chen Lab (CEMCS)</a><img src='https://pic.rmb.bdstatic.com/bjh/55c0e459e75644eddac50a39784e8d6a.png' style='height: 20px' alt=''></img>.",
      teamList: [
        [
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/e5bc4025775990ee800aea141a9696a4.jpeg",
            img: imgLuonanChen,
            name: "Luonan Chen",
            position: "Principal investigator",
            affiliation:
              "Shanghai lnstitute of Biochemistry and Cell Biology, CAS.",
            link: "http://cemcs.cas.cn/sourcedb_cemcs_cas/zw/pi/202008/t20200823_5670080.html",
            email: "lnchen@sibs.ac.cn",
          },
          // {
          //   // img: "https://pic.rmb.bdstatic.com/bjh/e5bc4025775990ee800aea141a9696a4.jpeg",
          //   img: imgKazuyukiAihara,
          //   name: "Kazuyuki Aihara",
          //   position: "Professor",
          //   affiliation:
          //     "International Research Center for Neurointelligence (WPI-IRCN), The University of Tokyo.",
          //   link: "https://ircn.jp/en/mission/people/kazuyuki_aihara",
          //   email: "kaihara@g.ecc.u-tokyo.ac.jp",
          // },
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/e5bc4025775990ee800aea141a9696a4.jpeg",
            img: imgRuiLiu,
            name: "Rui Liu",
            position: "Professor",
            affiliation:
              "School of Mathematics, South China University of Technology.",
            link: "http://www2.scut.edu.cn/math/2017/1227/c14582a242135/page.htm",
            email: "scliurui@scut.edu.cn",
          },
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/e5bc4025775990ee800aea141a9696a4.jpeg",
            img: imgPeiChen,
            name: "Pei Chen",
            position: "Professor",
            affiliation:
              "School of Mathematics, South China University of Technology.",
            link: "http://www2.scut.edu.cn/math/2018/0316/c14638a440120/page.htm",
            email: "chenpei@scut.edu.cn",
          },
        ],
        [
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/d96b55a45cb25edc04753f2f0f7c8715.jpeg",
            img: imgYuyanTong,
            name: "Yuyan Tong",
            position: "Doctor",
            affiliation:
              "School of Mathematics, South China University of Technology.",
            link: "https://github.com/TongYy1996/RSIT",
            email: "202110186793@mail.scut.edu.cn",
          },
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/d96b55a45cb25edc04753f2f0f7c8715.jpeg",
            img: imgRenhaoHong,
            name: "Renhao Hong",
            position: "Doctor",
            affiliation:
              "School of Mathematics, South China University of Technology.",
            link: "",
            email: "202110186784@mail.scut.edu.cn",
          },
          {
            // img: "https://pic.rmb.bdstatic.com/bjh/d96b55a45cb25edc04753f2f0f7c8715.jpeg",
            img: imgZeZhang,
            name: "Ze Zhang",
            position: "Master",
            affiliation: "Hangzhou Institute for Advanced Study, UCAS, CAS.",
            link: "https://github.com/ParallelLight",
            email: "zhangze21@mails.ucas.ac.cn",
          },
        ],
      ],
      contactMsg:
        "If any question about the Earthquake Alerting Tool, please contact us: <a href='mailto:scliurui@scut.edu.cn'>scliurui@scut.edu.cn</a>",
      // 定义走马灯的高度
      carouselHeight: "400px",
      // 页面宽度
      windowWidth: 1920,
    };
  },
  methods: {
    errorHandler() {
      return true;
    },
  },
  mounted() {
    var that = this;
    that.windowWidth = document.documentElement.clientWidth; // 宽
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        that.windowWidth = document.documentElement.clientWidth; // 宽
      })();
    };
  },
  watch: {
    windowWidth(val) {
      let that = this;
      if (val < 1080) {
        that.carouselHeight = "1600px";
      } else {
        that.carouselHeight = "400px";
      }
      // console.log("实时屏幕宽度：", val, that.windowHeight);
    },
  },
};
</script>

<style scoped>
/* block */
.block-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.block-card {
  width: 1320px;
  margin: 20px 0 100px 0;
}
.box-card-item {
  font-size: 20px;
  font-weight: bold;
}
.box-card-item-icon {
  color: #223468;
}
/* about-info */
.box-card-about-info {
  margin: 15px 0 20px 0;
  width: 100%;
}
.info-item {
  font-size: 18px;
  font-weight: bold;
}
.info-item-icon {
  color: #223468;
}
.info-item-content {
  margin: 15px 0 25px 0;
  width: 100%;
  font-size: 16px;
  text-align: justify;
  line-height: 1.6;
  white-space: pre-wrap;
}
/* About Our Team */
.our-team-content {
  margin-bottom: 30px;
  text-align: center;
}
.el-carousel {
  width: 100%;
}
.every-group-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.person-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.person-img {
  width: 130px;
  height: 150px;
}
.angles-up {
  margin: 10px 0 10px 0;
  font-size: 17px;
  color: #223468;
}
.person-card {
  padding: 14px;
  /* width: 284px; */
  width: 85%;
  font-size: 14px;
  line-height: 24px;
  color: #9a9a9a;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
.person-title {
  margin-bottom: 10px;
}
.global-visitors {
  text-align: center;
}
.global-visitors-img {
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
/* 判断屏幕宽度小于1320px后使用百分比 */
@media screen and (max-width: 1320px) {
  .block-card {
    width: 90%;
  }
}
@media screen and (max-width: 1080px) {
  .every-group-item {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
</style>