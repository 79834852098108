<template>
  <div>
    <div class="block-container">
      <span class="block-title">Earthquake Alerting by RSIT</span>
      <!-- Custom property Begin -->
      <el-card class="block-card">
        <div class="box-card-item">
          <span class="box-card-item-icon">
            <font-awesome-icon icon="fa-solid fa-dna" />
          </span>
          <span> Input: Custom property</span>
        </div>
        <div class="box-card-item-text">Please set on the card below</div>
        <!-- Input -->
        <div class="select-container">
          <div class="select-components">
            <div class="select-title">Region selection</div>
            <div class="select-content">
              <el-select
                v-model="nationValue"
                style="width: 100%"
                filterable
                @change="changeNation(nationValue)"
              >
                <el-option
                  v-for="item in nationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="select-components">
            <div class="select-title">
              <span>Time selection</span>
              <span style="margin-left: 3%">
                <el-radio-group v-model="radio" @change="changeRadio(radio)">
                  <el-radio :disabled="!isOptional" :label="1"
                    >Input GNSS data</el-radio
                  >
                  <el-radio :disabled="!isOptional" :label="2"
                    >Input waveform data</el-radio
                  >
                </el-radio-group>
              </span>
            </div>
            <div class="select-content">
              <el-select
                v-model="timeValue"
                style="width: 100%"
                @change="changeTime(nationValue, timeValue)"
              >
                <el-option
                  v-for="item in timeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <!-- Small Alert -->
        <el-alert
          title="The Data is being updated continuously!"
          type="warning"
          description="Some data are based on www.jma.go.jp and www.jisinyosoku.com."
          show-icon
        >
        </el-alert>
      </el-card>
      <!-- Custom property End -->
      <!-- Prediction Results Begin -->
      <el-card class="block-card" style="margin-bottom: 100px">
        <div class="box-card-item">
          <span class="box-card-item-icon">
            <font-awesome-icon icon="fa-solid fa-dna" />
          </span>
          <span> Output: Earthquake alerting result</span>
        </div>
        <div class="box-card-item-text">{{ outputTip }}</div>
        <el-divider></el-divider>
        <!-- Output -->
        <div class="block-card_result">
          <!-- <div id="myChart" :style="{ width: '300px', height: '300px' }"></div> -->
          <!-- <div id="myResult" :style="{ width: '100%', height: '700px' }"></div> -->
          <div id="myResult" :style="{ width: '100%', height: '100%' }"></div>
        </div>
        <el-divider></el-divider>
        <div class="map-img">
          <el-image fit="fill" :src="mapImg"></el-image>
        </div>
      </el-card>
      <!-- Prediction Results Begin -->
    </div>
  </div>
</template>

<script>
import mapAlaska from "/public/img/maps/mapAlaska.png";
import mapEastJapan from "/public/img/maps/mapEastJapan.png";
import mapHokkaido from "/public/img/maps/mapHokkaido.png";
import mapIbaraki from "/public/img/maps/mapIbaraki.png";
import mapKumamoto from "/public/img/maps/mapKumamoto.png";
import mapMiyagi from "/public/img/maps/mapMiyagi.png";
import mapSiChuanGNSS from "/public/img/maps/mapSiChuanGNSS.png";
import mapSiChuanWaveform from "/public/img/maps/mapSiChuanWaveform.png";

// 引入echart配置核心与组件
import * as echarts from "echarts/core";
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  GraphicComponent,
  MarkLineComponent,
  MarkPointComponent,
} from "echarts/components";
import { BarChart, LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { SVGRenderer } from "echarts/renderers";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  BarChart,
  LineChart,
  SVGRenderer,
  UniversalTransition,
  GraphicComponent,
  MarkLineComponent,
  MarkPointComponent,
  CanvasRenderer,
]);

import starImg from "../assets/star.svg";
// import earthquakeImg from "../assets/earthquake.svg";
import earthquakeImg from "../assets/house-crack-0-0-226.svg";
import smallEarthquakeImg from "../assets/house-crack-77-140-238.svg";

export default {
  name: "EarthquakePrediction",
  data() {
    return {
      nationValue: 0,
      nationOptions: [
        {
          value: 0,
          label: "Alaska, USA",
        },
        {
          value: 1,
          label: "East Japan, Japan",
        },
        {
          value: 2,
          label: "Hokkaido, Japan",
        },
        {
          value: 3,
          label: "Ibaraki, Japan",
        },
        {
          value: 4,
          label: "Kumamoto, Japan",
        },
        {
          value: 5,
          label: "Miyagi, Japan",
        },
        {
          value: 6,
          label: "Changning, Sichuan, China",
        },
        {
          value: 7,
          label: "Jiuzhaigou, Sichuan, China",
        },
        {
          value: 8,
          label: "Sichuan, China",
        },
      ],
      radioOptionalList: [0, 6, 7], // 是否具有GNSS和Waveform两种曲线
      radio: 1,
      timeValue: "last_update",
      timeOptions: [],
      dataset: [],
      // dataDetailDict: {},
      mapImgArray: [
        [mapAlaska, mapAlaska],
        [mapEastJapan],
        [mapHokkaido],
        [mapIbaraki],
        [mapKumamoto],
        [mapMiyagi],
        [mapSiChuanGNSS, mapSiChuanWaveform],
        [mapSiChuanGNSS, mapSiChuanWaveform],
        [mapSiChuanGNSS],
      ],
      outputTip:
        "When a RSIT signal (denoted by red bar) appears, it means that an earthquake may occur within two weeks. Each earthquake event is presented by blue bars with its magnitude.",
    };
  },
  mounted() {
    this.getTimeOptionsDict(1, 0);
    this.getPredictResult(1, 0, "last_update");
    // this.getData();
    // this.drawResults(this.datasetDict[this.nationValue][this.timeValue]);
  },
  methods: {
    getTimeOptionsDict(r, n) {
      var that = this;
      this.$axios
        .get("time/", {
          params: {
            radio_id: r,
            nation_id: n,
          },
        })
        .then(function (response) {
          // console.log(response.data.timeOptions);
          that.timeValue = response.data.timeValue;
          that.timeOptions = response.data.timeOptions;
        })
        .catch(function (err) {
          alert(err);
        });
    },
    getPredictResult(r, n, t) {
      // console.log(n, t);
      var that = this;
      this.$axios
        .get("data/", {
          params: {
            radio_id: r,
            nation_id: n,
            time_value: t,
          },
        })
        .then(function (response) {
          // 获取data_detail内容
          // console.log(that.nationOptions[n].label.split(",")[0]);
          that.getDataDetail(
            response.data.dataset,
            that.nationOptions[n].label,
            that.radio
          );
          // console.log(response);
          // that.dataset = response.data.dataset;
          // console.log(n);
          // console.log(that.nationOptions[n].label);
          // that.drawResults(
          //   response.data.dataset,
          //   that.nationOptions[n].label,
          //   that.radio
          // );
        })
        .catch(function (err) {
          alert(err);
        });
    },
    // 获取data_detail内容
    getDataDetail(dataset, nationLabel, radio) {
      var that = this;
      this.$axios
        .get("detail/", {
          params: {
            nation: nationLabel.split(",")[0],
          },
        })
        .then(function (response) {
          // console.log(response.data.data_detail);
          that.dataDetailDict = response.data.data_detail;
          // 绘制
          that.drawResults(dataset, nationLabel, radio);
        })
        .catch(function (err) {
          alert(err);
        });
    },
    changeNation(n) {
      // console.log(n);
      this.radio = 1;
      this.getTimeOptionsDict(this.radio, n);
      this.getPredictResult(this.radio, n, "last_update");
    },
    changeTime(n, t) {
      // console.log(n, t);
      this.getPredictResult(this.radio, n, t);
    },
    changeRadio(r) {
      // console.log(r);
      this.getTimeOptionsDict(r, this.nationValue);
      this.getPredictResult(r, this.nationValue, "last_update");
    },
    drawResults(dataset, title_nation, data_type) {
      var chartDom = document.getElementById("myResult");
      var myChart = echarts.init(chartDom);
      var option;
      // let colors = ["#FF0000", "#0000E2", "#00CC32", "#FF00FF"];
      let colors = ["#FF0000", "#0000E2", "#548235", "#C55A11", "#4D8CEE"];
      let title_text = "Earthquake Alerting Results of " + title_nation;
      // console.log(dataset[4][0], dataset[4][dataset[4].length - 1]);
      let sub_title_text =
        "from " + dataset[4][0] + " to " + dataset[4][dataset[4].length - 1];

      // 标记红星，得到对应索引序列
      // console.log(dataset[2]);
      let composite_markpoint = [];
      for (var i = 0; i < dataset[2].length; i++) {
        let star_marker = {
          // name: "red star marker",
          coord: [],
          symbol: "image://" + starImg,
        };
        if (dataset[2][i] == 1) {
          // console.log(i);
          star_marker["coord"] = [i, 1];
          composite_markpoint.push(star_marker);
        }
      }
      // console.log(composite_markpoint);

      // 标记震级，得到真实地震序列
      // console.log(dataset[3]);
      let magnitude_markpoint = [];
      for (var i = 0; i < dataset[3].length; i++) {
        let magnitude_marker = {
          // name: "red star marker",
          coord: [],
          symbol: "image://" + earthquakeImg,
        };
        if (dataset[3][i] != 0) {
          // console.log(i);
          magnitude_marker["coord"] = [i, dataset[3][i]];
          magnitude_markpoint.push(magnitude_marker);
        }
      }
      // 标记小震级，得到小震级地震序列
      let small_magnitude_markpoint = [];
      for (var i = 0; i < dataset[5].length; i++) {
        let small_magnitude_marker = {
          coord: [],
          symbol: "image://" + smallEarthquakeImg,
        };
        if (dataset[5][i] != 0) {
          // console.log(i);
          small_magnitude_marker["coord"] = [i, dataset[5][i]];
          small_magnitude_markpoint.push(small_magnitude_marker);
        }
      }

      // 获取datadetail内容数据
      let dataDetailDictContent = this.dataDetailDict;

      // echart图表option配置内容
      option = {
        animationDuration: 3000,
        title: {
          text: title_text,
          subtext: sub_title_text,
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: false,
          },
          formatter: function (params) {
            var htmlStr = "";
            var seriesNameList = {
              "RSIT signal":
                "Composite earthquake warning signal (RSIT signal)",
              Magnitude: "Outbreak of an earthquake (Magnitude>=5)",
              "magnitude": "Outbreak of a small earthquake (magnitude<5)",
              "Fluctuation-detection Index": "Fluctuation-detection Index",
              "Unpredictability-detection Index":
                "Unpredictability-detection Index",
            };
            for (var i = 0; i < params.length; i++) {
              var param = params[i];
              var xName = param.name; // x轴的名称
              // var seriesName = param.seriesName; // 图例名称
              var seriesName = seriesNameList[param.seriesName]; // 图例名称
              var value = param.value; // y轴值
              var color = param.color; // 图例颜色

              if (i === 0) {
                htmlStr +=
                  '<span style="font-weight: bold">' +
                  xName +
                  "</span>" +
                  "<br/>"; // x轴的名称
              }
              htmlStr += "<div>";

              // 正常显示的数据，走默认
              htmlStr +=
                '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:' +
                color +
                ';"></span>';

              htmlStr +=
                seriesName +
                "：" +
                '<span style="font-weight: bold">' +
                // value.toFixed(3) +
                // 保留三位小数
                Math.round(value * 1000) / 1000 +
                "</span>";

              htmlStr += "</div>";
            }
            // 判断是否悬浮于红柱
            let p_flag = params[0];
            if (
              data_type === 1 &&
              p_flag.seriesName === "RSIT signal" &&
              p_flag.value === 1
            ) {
              // console.log("触碰红柱");
              // console.log(title_nation.split(",")[0]);
              // console.log(p_flag.name);
              // console.log(dataDetailDictContent[p_flag.name]);
              htmlStr += "<br/><div>";
              htmlStr +=
                '<span style="font-weight: bold">Earthquake situation in 14 days</span><br/><span style="color: red">click the red bar to show the red frame range (about 14 days)</span><br/>'; // 14天内地震情况

              // 显示自定义数据
              if (dataDetailDictContent[p_flag.name]) {
                for (
                  var i = 0;
                  i < dataDetailDictContent[p_flag.name].length;
                  i++
                ) {
                  htmlStr +=
                    '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:#0000E2;"></span>';
                  htmlStr +=
                    "<span>Date：" +
                    dataDetailDictContent[p_flag.name][i][1] +
                    "</span><br/>";
                  htmlStr +=
                    "<span>Earthquake nation：" +
                    dataDetailDictContent[p_flag.name][i][0] +
                    "</span><br/>";
                  htmlStr +=
                    "<span>Epicenter place name：" +
                    dataDetailDictContent[p_flag.name][i][2] +
                    "</span><br/>";
                  htmlStr +=
                    "<span>Earthquake depth：" +
                    dataDetailDictContent[p_flag.name][i][3] +
                    "</span><br/>";
                  htmlStr +=
                    "<span>Earthquake magnitude：" +
                    dataDetailDictContent[p_flag.name][i][4] +
                    "</span><br/>";
                }
              } else {
                htmlStr +=
                  '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:#0000E2;"></span>';
                htmlStr += "To be updating!";
              }
              htmlStr += "</div>";
            }
            return htmlStr;
          },
        },
        legend: {
          show: true,
          data: [
            {
              name: "RSIT signal",
              // name: "Composite earthquake warning signal",
            },
            {
              name: "Magnitude",
              // name: "Outbreak of an earthquake (magnitude)",
            },
            {
              name: "magnitude",
              // name: "Outbreak of an earthquake (magnitude)",
            },
            {
              name: "Fluctuation-detection Index",
            },
            {
              name: "Unpredictability-detection Index",
            },
          ],
          top: "7%",
        },
        toolbox: {
          show: true,
          // orient: "vertical",
          feature: {
            dataZoom: {
              yAxisIndex: "none",
              title: {
                zoom: "Area Zoom",
                back: "Area Zoom Restore",
              },
            },
            dataView: { show: true, readOnly: true, title: "Data View" }, // 数据试图
            magicType: {
              show: true,
              type: ["line", "bar"],
              title: {
                line: "Switch to Bar Chart",
                bar: "Switch to Line Chart",
              },
            }, //折线图与柱状图互转
            restore: { show: true, title: "Restore" }, // 还原
            saveAsImage: { show: true, title: "Save as Image" }, //保存为图片
          },
        },
        calculable: true,
        axisPointer: {
          link: [
            {
              xAxisIndex: "all",
            },
          ],
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 28,
            end: 72,
            xAxisIndex: [0, 1],
          },
          {
            type: "inside",
            realtime: true,
            start: 28,
            end: 72,
            xAxisIndex: [0, 1],
          },
        ],
        // 添加自定义文本
        graphic: [
          {
            id: "datazoom",
            type: "text",
            left: "center",
            top: "bottom",
            style: {
              text: "Data zoom scroll bar",
              font: "14px Microsoft YaHei",
            },
          },
          {
            id: "rect_window",
            type: "rect",
          },
        ],
        grid: [
          {
            top: "12%",
            height: "34%",
          },
          {
            top: "53%",
            height: "34%",
          },
        ],
        xAxis: [
          {
            gridIndex: 0,
            type: "category",
            boundaryGap: false,
            axisLine: { onZero: true },
            data: dataset[4],
          },
          {
            gridIndex: 1,
            type: "category",
            boundaryGap: false,
            axisLine: { onZero: true },
            data: dataset[4],
            position: "top",
          },
        ],
        yAxis: [
          {
            gridIndex: 0,
            name: "RSIT signal",
            // name: "Composite earthquake warning signal",
            type: "value",
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0],
              },
            },
            min: 0,
            max: 1.4,
            interval: 1,
            axisLabel: {
              showMaxLabel: false, // 不展示最大刻度线
            },
            // axisLabel: {
            //   formatter: "{value} ml",
            // },
          },
          {
            gridIndex: 0,
            name: "Magnitude",
            type: "value",
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[1],
              },
            },
            min: 0,
            max: 10,
            interval: 1,
          },
          {
            gridIndex: 0,
            name: "magnitude",
            type: "value",
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[4],
              },
            },
            min: 0,
            max: 10,
            interval: 1,
          },
          {
            gridIndex: 1,
            name: "Fluctuation-detection Index",
            type: "value",
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[2],
              },
            },
            inverse: true,
          },
          {
            gridIndex: 1,
            name: "Unpredictability-detection Index",
            type: "value",
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[3],
              },
            },
            inverse: true,
          },
        ],
        /**
         * dataset[2]-#FF0000-RSIT signal(double_single.mat)(single)
         * dataset[3]-#0000E2-Magnitude(real_earthquake_Y2.mat)(Y2)
         * dataset[0]-#548235-Fluctuation-detection Index(FD_index.mat)(Y1)
         * dataset[1]-#C55A11-Unpredictability-detection Index(UD_index.mat)(mmm)
         * dataset[4]-time
         * dataset[5]-#4D8CEE-magnitude(small_earthquake.mat)(small_earthquake)
         */
        series: [
          {
            name: "RSIT signal",
            // name: "Composite earthquake warning signal",
            type: "bar",
            color: colors[0],
            xAxisIndex: 0,
            yAxisIndex: 0,
            symbolSize: 8,
            data: dataset[2],
            markPoint: {
              // data: [
              //   {
              //     name: "red star marker",
              //     coord: [29, 1],
              //     symbol: "image://" + starImg,
              //   },
              // ],
              data: composite_markpoint,
              symbolSize: 32,
              symbolOffset: [0, "-20%"],
              label: {
                show: false,
              },
            },
          },
          {
            name: "Magnitude",
            // name: "An earthquake with its magnitude",
            // name: "Outbreak of an earthquake (magnitude)",
            type: "bar",
            color: colors[1],
            xAxisIndex: 0,
            yAxisIndex: 1,
            symbolSize: 8,
            data: dataset[3],
            label: {
              show: true,
              position: "top",
              formatter: function (val) {
                if (val.value == 0) {
                  return "";
                }
              },
              textStyle: {
                color: colors[1],
              },
            },
            markPoint: {
              data: magnitude_markpoint,
              symbolSize: 16,
              symbolOffset: [0, "-180%"],
            },
            markLine: {
              data: [{ name: "the 5th Magnitude", yAxis: 5 }],
            },
          },
          {
            name: "magnitude",
            // name: "An earthquake with its magnitude",
            // name: "Outbreak of an earthquake (magnitude)",
            type: "bar",
            color: colors[4],
            xAxisIndex: 0,
            yAxisIndex: 2,
            symbolSize: 8,
            data: dataset[5],
            label: {
              show: true,
              position: "top",
              formatter: function (val) {
                if (val.value == 0) {
                  return "";
                }
              },
              textStyle: {
                color: colors[4],
              },
            },
            markPoint: {
              data: small_magnitude_markpoint,
              symbolSize: 16,
              symbolOffset: [0, "-180%"],
            },
            // markLine: {
            //   data: [{ name: "the 3th Magnitude", yAxis: 3 }],
            // },
          },
          {
            name: "Fluctuation-detection Index",
            type: "line",
            color: colors[2],
            xAxisIndex: 1,
            yAxisIndex: 3,
            symbolSize: 8,
            data: dataset[1],
          },
          {
            name: "Unpredictability-detection Index",
            type: "line",
            color: colors[3],
            xAxisIndex: 1,
            yAxisIndex: 4,
            symbolSize: 8,
            data: dataset[0],
          },
        ],
      };
      myChart.setOption(option);

      // echart图表自适应分辨率
      window.addEventListener("resize", function () {
        myChart.resize();
      });

      // 处理点击事件
      myChart.off("click"); //防止触发两次点击事件
      myChart.on("click", function (params) {
        // console.log(data_type);
        // 判断是否是预警信号 判断是否是GNSS类型数据
        if (params.data == 1 && data_type == 1) {
          // console.log(params);
          // console.log(params.dataIndex);
          // console.log(params.event.offsetX); // window的起始位置
          // console.log(params.event.target.shape.width); // 当前x轴刻度的宽度
          let window_width = params.event.target.shape.width * 46; // window的宽度
          myChart.setOption({
            graphic: {
              id: "rect_window",
              $action: "replace",
              type: "rect",
              x: params.event.offsetX,
              y: 200,
              shape: {
                width: window_width,
                height: 120,
              },
              style: {
                fill: "white", // 填充样式
                stroke: "#FF0000", // 描边样式
                opacity: 1, // 不透明度
                lineDash: 2, // 描边虚线样式
                lineWidth: 5,
              },
              enterFrom: {
                // 淡入
                style: { opacity: 0 },
                // 从左飞入
                x: 0,
              },
              leaveTo: {
                // 淡出
                style: { opacity: 0 },
              },
            },
          });
          // 设置时间淡出
          setTimeout(() => {
            myChart.setOption({
              graphic: {
                id: "rect_window",
                $action: "replace",
                type: "rect",
              },
            });
          }, 5000);
        }
      });
    },
  },
  computed: {
    isOptional() {
      if (this.radioOptionalList.indexOf(this.nationValue) != -1) {
        return true;
      } else {
        return false;
      }
    },
    mapImg() {
      return this.mapImgArray[this.nationValue][this.radio - 1];
    },
  },
};
</script>

<style scoped>
/* block */
.block-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.block-title {
  font-size: 34px;
  font-weight: bold;
  margin: 3% 0 1% 0;
}
.block-card {
  width: 1320px;
  margin: 20px 0;
}
.box-card-item {
  font-size: 20px;
  font-weight: bold;
}
.box-card-item-icon {
  color: #223468;
}
.box-card-item-text {
  /* margin: 10px 0 0 0; */
  margin: 10px 0 0 0;
  width: 100%;
  /* font-size: 16px; */
  text-align: justify;
  line-height: 1.3;
  white-space: pre-wrap;
}
.block-card_result {
  width: 100%;
  height: 700px;
}
/* select */
.select-container {
  margin: 15px 0 20px 0;
  width: 100%;
  /* height: 100px; */
  background-color: #f4f6fc;
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  /* border: 1px solid green; */
}
.select-components {
  width: 100%;
  padding: 1.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid red; */
}
.select-title {
  font-size: 16px;
  margin: 0 0 10px 0;
}
.map-img {
  padding: 0 20%;
}
/* 判断屏幕宽度小于1320px后使用百分比 */
@media screen and (max-width: 1320px) {
  .block-card {
    width: 90%;
  }
  .select-container {
    flex-direction: column;
  }
  .select-components {
    margin: 2% 0;
    padding: 0;
    /* border: 1px solid red; */
  }
}
/* 自适应修改图片大小 */
@media screen and (max-width: 760px) {
  .block-title {
    font-size: 26px;
  }
  .map-img {
    padding: 0;
  }
}
@media screen and (max-width: 425px) {
  .block-title {
    font-size: 22px;
  }
}
@media screen and (max-width: 320px) {
  .block-title {
    font-size: 18px;
  }
}
</style>