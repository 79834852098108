<template>
  <div class="footer">
    <div class="footer-content">
      <div class="footer-record">Earthquake Alerting</div>
      <div class="footer-company">Copyright © 2022-2023. All rights reserved.</div>
      <div>Tong, Y., Hong, R., Zhang, Z., Aihara, K., Chen, P., Liu, R., & Chen, L. (2023). Earthquake alerting based on spatial geodetic data by spatiotemporal information transformation learning. Proceedings of the National Academy of Sciences of the United States of America, 120(37), e2302275120. <a href="http://www2.scut.edu.cn/_upload/article/files/b9/39/43d1c1ee4c429e2fb54542100ee0/5561d02b-00cf-45ad-b6b9-73f6b353eb4f.pdf" target="_blank" style="color: aqua;
      ">https://doi.org/10.1073/pnas.2302275120</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
.footer {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282828;
}
.footer-content {
  width: 1280px;
  text-align: center;
  color: #fff;
}
.footer-record {
  font-size: 16px;
}
.footer-company {
  font-size: 14px;
  margin-bottom: 30px;
}
</style>