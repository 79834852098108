<template>
  <div id="app">
    <Backtop></Backtop>
    <div class="navigate-bar">
      <NavigateBar></NavigateBar>
    </div>
    <div class="body-footer-container">
      <div class="router-view">
        <router-view></router-view>
      </div>
      <div class="footer">
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import NavigateBar from "./layout/NavigateBar.vue";
import Footer from "./layout/Footer.vue";
import Backtop from "./components/Backtop.vue";

export default {
  name: "App",
  components: {
    NavigateBar,
    Footer,
    Backtop,
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
}
/* #app .navigate-bar { */
.navigate-bar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
.body-footer-container {
  position: absolute;
  top: 65px;
  width: 100%;
  /* background-color: #f5f5f5; */
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
/* #app .router-view { */
.router-view {
  flex: 1;
}
/* #app .footer { */
.footer {
  flex: 0;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
