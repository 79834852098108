<template>
  <div>
    <!-- Banner -->
    <div class="banner">
      <div class="banner-text">
        <span class="banner-text-title">Earthquake Alerting</span>
        <div class="banner-text-desc">{{ bannerText }}</div>
      </div>
    </div>
    <!-- Block -->
    <div class="block-container">
      <el-alert title="Our work has been accepted by PNAS." type="success"
        description="Citation: Tong, Y., Hong, R., Zhang, Z., Aihara, K., Chen, P., Liu, R., & Chen, L. (2023). Earthquake alerting based on spatial geodetic data by spatiotemporal information transformation learning. Proceedings of the National Academy of Sciences of the United States of America, 120(37), e2302275120. https://doi.org/10.1073/pnas.2302275120" show-icon>
      </el-alert>
      <el-card class="block-card">
        <!-- Earthquake Prediction -->
        <div id="earthquake-prediction" class="box-card-earthquake-prediction">
          <div class="box-card-item">
            <span class="box-card-item-icon">
              <font-awesome-icon icon="fa-solid fa-circle-nodes" />
            </span>
            <span> Earthquake Alerting</span>
          </div>
          <div class="box-card-earthquake-prediction-content">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="home-img">
                  <el-image style="width: 100%; height: 100%" fit="fill" :src="homeImg1"></el-image>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="home-img">
                  <el-image style="width: 100%; height: 100%" fit="fill" :src="homeImg2"></el-image>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="home-img-tip">
            <font-awesome-icon icon="fa-solid fa-circle-info" />
            <span v-html="imgTip"></span>
          </div>
        </div>
        <el-divider></el-divider>
        <!-- Monitoring Map -->
        <div id="monitoring-map" class="box-card-monitoring-map">
          <div class="box-card-item">
            <span class="box-card-item-icon">
              <font-awesome-icon icon="fa-solid fa-circle-nodes" />
            </span>
            <span> Monitoring Map</span>
          </div>
          <div class="box-card-item-text">{{ outputTip }}</div>
          <div class="box-card-monitoring-map-content">
            <div id="echartMap" class="echart-world-map"></div>
          </div>
        </div>
      </el-card>
      <!-- Global Visitors -->
      <!-- <el-card class="block-card" hidden> -->
      <el-card class="block-card">
        <div class="box-card-item">
          <span class="box-card-item-icon">
            <font-awesome-icon icon="fa-solid fa-bookmark" />
          </span>
          <span> Global Visitors</span>
        </div>
        <div class="info-item-content">
          <div class="global-visitors">
            <a href="https://clustrmaps.com/site/1bpqx" title="Visit tracker" target="_blank">
              <img style="width: 100%;"
              src="//www.clustrmaps.com/map_v2.png?w=1008&t=tt&d=PktRdM8OKxPsNhr2pZO-GPBcle3pD60JWQn8eL80Z5I&cl=ffffff" /></a>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import homeImg1 from "/public/img/home/网站封面图片1.png";
import homeImg2 from "/public/img/home/网站封面图片2.png";

import worldMapJson from "../assets/world.json";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  GeoComponent,
} from "echarts/components";
import { MapChart } from "echarts/charts";
import { EffectScatterChart } from "echarts/charts";
import { CustomChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  GeoComponent,
  MapChart,
  EffectScatterChart,
  CustomChart,
  CanvasRenderer,
]);

export default {
  name: "Home",
  data() {
    return {
      bannerText:
        "Alerting imminent earthquakes is particularly challenging due to the high nonlinearity and nonstationarity of geodynamical phenomena. Based on spatiotemporal information transformation (STI) for high-dimensional real-time data, a model-free framework, i.e., real-time spatiotemporal information transformation learning (RSIT), is developed for extending the nonlinear and nonstationary time series. RSIT explores both the spatial and temporal dynamics of real-world data on the basis of a solid theoretical background in nonlinear dynamics and delay-embedding theory.",
      homeImg1: homeImg1,
      homeImg2: homeImg2,
      imgTip:
        " The first image is from <a href='https://www.jma.go.jp/jma/index.html' target='_blank'>Japan Meteorological Agency</a>, and the second image is from <a href='https://www.usgs.gov/', target='_blank'>USGS</a>.",
      outputTip:
        "Earthquakes in Alaska, East Japan, Hokkaido, Ibaraki, Kumamoto, Miyagi and Sichuan are under continuous monitoring.",
    };
  },
  mounted() {
    this.drawMonitoringMap();
  },
  methods: {
    // 绘制监控地图
    drawMonitoringMap() {
      echarts.registerMap("world", worldMapJson);
      var myChart = echarts.init(document.getElementById("echartMap")); // 基于准备好的dom，初始化echarts实例
      var areaData = [
        [-149.4936733, 64.2008413, "Alaska"],
        [138.252924, 36.204824, "East Japan"],
        [142.8634737, 43.2203266, "Hokkaido"],
        [140.4245362, 36.2869435, "Ibaraki"],
        [130.7079369, 32.8032164, "Kumamoto"],
        [141.1193048, 38.630612, "Miyagi"],
        [104.92756445509465, 28.588133452698838, "Changning, Sichuan"],
        [104.24954673402509, 33.257591509496066, "Jiuzhaigou, Sichuan"],
        [104.08282275849255, 30.657041771323716, "Sichuan"],
      ];
      var option = {
        title: {
          text: "Earthquake Alerting Monitoring Map by RSIT",
          subtext: "The World Map Data from www.echarts.apache.org",
          sublink:
            "https://echarts.apache.org/examples/data/asset/geo/world.json",
          left: "left",
        },
        tooltip: {},
        // visualMap: {
        //   left: "left",
        //   min: 500000,
        //   max: 38000000,
        //   inRange: {
        //     color: [
        //       "#313695",
        //       "#4575b4",
        //       "#74add1",
        //       "#abd9e9",
        //       "#e0f3f8",
        //       "#ffffbf",
        //       "#fee090",
        //       "#fdae61",
        //       "#f46d43",
        //       "#d73027",
        //       "#a50026",
        //     ],
        //   },
        //   text: ["High", "Low"],
        //   calculable: true,
        // },
        toolbox: {
          show: true,
          left: "right",
          top: "top",
          feature: {
            restore: {},
            saveAsImage: {},
          },
        },
        // World Map
        geo: {
          tooltip: {
            show: false,
          },
          name: "World Map",
          type: "map",
          roam: true,
          map: "world",
          // 设置为一张完整经纬度的世界地图
          map: "world",
          left: 0,
          top: "8%",
          right: 0,
          bottom: 0,
          boundingCoords: [
            // 定位左上角经纬度
            [-180, 90],
            // 定位右下角经纬度
            [180, -90],
          ],
          itemStyle: {
            areaColor: "#f2f5ea", // 地区颜色
          },
          emphasis: {
            label: {
              show: true,
            },
          },
          // data: [
          //   { name: "Alabama", value: 4822023 },
          //   { name: "Alaska", value: 731449 },
          //   { name: "Arizona", value: 6553255 },
          // ],
        },
        // EffectScatter
        // series: {
        //   name: "Monitoring Area",
        //   type: "effectScatter",
        //   coordinateSystem: "geo",
        //   geoIndex: 0,
        //   symbolSize: function (params) {
        //     return (params[2] / 100) * 15 + 5;
        //   },
        //   itemStyle: {
        //     color: "#b02a02",
        //   },
        //   encode: {
        //     tooltip: 2,
        //   },
        //   data: [
        //     [-149.4936733, 64.2008413, 100],
        //     [138.252924, 36.204824, 10],
        //     [142.8634737, 43.2203266, 10],
        //     [140.4245362, 36.2869435, 10],
        //     [130.7079369, 32.8032164, 10],
        //     [141.1193048, 38.630612, 10],
        //     [104.92756445509465, 28.588133452698838, 10],
        //     [104.24954673402509, 33.257591509496066, 10],
        //     [104.08282275849255, 30.657041771323716, 10],
        //   ],
        // },
        // CustomScatter
        series: {
          tooltip: {
            show: true,
            trigger: "item",
            showDelay: 0,
            transitionDuration: 0.2,
            formatter: function (params) {
              // console.log(params);
              var htmlStr =
                "<div><span style='margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:red;'></span>" +
                "<span>" +
                params.data[2] +
                "</span></div>";
              return htmlStr;
            },
          },
          type: "custom",
          coordinateSystem: "geo",
          geoIndex: 0,
          zlevel: 1,
          data: areaData,
          renderItem(params, api) {
            const coord = api.coord([
              api.value(0, params.dataIndex),
              api.value(1, params.dataIndex),
            ]);
            const circles = [];
            for (let i = 0; i < 5; i++) {
              circles.push({
                type: "circle",
                shape: {
                  cx: 0,
                  cy: 0,
                  r: 50,
                },
                style: {
                  stroke: "red",
                  fill: "none",
                  lineWidth: 2,
                },
                // Ripple animation
                keyframeAnimation: {
                  duration: 3000,
                  loop: true,
                  delay: (-i / 4) * 4000,
                  keyframes: [
                    {
                      percent: 0,
                      scaleX: 0,
                      scaleY: 0,
                      style: {
                        opacity: 1,
                      },
                    },
                    {
                      percent: 1,
                      scaleX: 1,
                      scaleY: 0.4,
                      style: {
                        opacity: 0,
                      },
                    },
                  ],
                },
              });
            }
            return {
              type: "group",
              x: coord[0],
              y: coord[1],
              children: [
                ...circles,
                {
                  type: "path",
                  shape: {
                    d: "M16 0c-5.523 0-10 4.477-10 10 0 10 10 22 10 22s10-12 10-22c0-5.523-4.477-10-10-10zM16 16c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6z",
                    x: -10,
                    y: -35,
                    width: 20,
                    height: 40,
                  },
                  style: {
                    fill: "red",
                  },
                  // Jump animation.
                  keyframeAnimation: {
                    duration: 2000,
                    loop: true,
                    delay: Math.random() * 1000,
                    keyframes: [
                      {
                        y: -10,
                        percent: 0.5,
                        easing: "cubicOut",
                      },
                      {
                        y: 0,
                        percent: 1,
                        easing: "bounceOut",
                      },
                    ],
                  },
                },
              ],
            };
          },
        },
      };
      myChart.setOption(option);
      myChart.getZr().on("click", function (params) {
        var pixelPoint = [params.offsetX, params.offsetY];
        var dataPoint = myChart.convertFromPixel({ geoIndex: 0 }, pixelPoint);
        console.log(dataPoint);
      });

      // echart图表自适应分辨率
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style scoped>
/* banner */
.banner {
  width: 100%;
  height: 400px;
  /* background: url(https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg)
    center center; */
  background: url("@/assets/wave.png") center center;
  /* background-size: contain; */
  /* background-color: aquamarine; */
  background-color: lightblue;
  position: relative;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-text {
  width: 1320px;
  color: #fff;
  position: relative;
  top: 80px;
  left: 0;
  /* border: 1px solid yellow; */
}

.banner-text-title {
  font-size: 42px;
  font-weight: bold;
}

.banner-text-desc {
  margin-top: 5px;
  width: 800px;
  font-size: 19px;
  line-height: 1.4;
  text-align: justify;
  /* border: 1px solid red; */
}

/* block */
.block-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid red; */
}

.block-card {
  width: 1320px;
  /* height: 500px; */
  margin: 20px 0 100px 0;
}

.box-card-item {
  font-size: 20px;
  font-weight: bold;
}

.box-card-item-icon {
  color: #223468;
}

.box-card-earthquake-prediction-content {
  margin: 30px 0 0 0;
}

.home-img-tip {
  margin: 10px 0 0 0;
}

/* monitoring-map */
.box-card-item-text {
  /* margin: 10px 0 0 0; */
  margin: 10px 0 0 0;
  width: 100%;
  /* font-size: 16px; */
  text-align: justify;
  line-height: 1.3;
  white-space: pre-wrap;
}

.box-card-monitoring-map-content {
  margin: 20px 0;
  width: 100%;
}

.echart-world-map {
  width: 100%;
  height: 700px;
  /* margin: 0 auto; */
  background-color: #b7d5ff;
  border: 1px solid #eeeeee;
  background-size: 100% 100%;
}

/* global-visitors */
.info-item-content {
  margin: 15px 0 25px 0;
  width: 100%;
  font-size: 16px;
  text-align: justify;
  line-height: 1.6;
  white-space: pre-wrap;
}

.global-visitors {
  text-align: center;
}

.global-visitors-img {
  /* padding: 10px; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04); */
}

/******************************************************************************** */
/* 判断屏幕宽度小于1320px后使用百分比 */
@media screen and (max-width: 1320px) {
  .block-card {
    width: 90%;
  }

  .banner-text {
    width: 90%;
  }

  .banner-text-desc {
    width: 100%;
  }
}

/* 判断屏幕宽度小于920px后使用百分比 */
@media screen and (max-width: 768px) {
  .banner {
    height: 470px;
  }
}

@media screen and (max-width: 565px) {
  .banner {
    height: 490px;
  }

  .banner-text-title {
    font-size: 28px;
  }

  .banner-text-desc {
    font-size: 18px;
  }
}

@media screen and (max-width: 425px) {
  .banner {
    height: 500px;
  }

  .banner-text-title {
    font-size: 26px;
  }

  .banner-text-desc {
    font-size: 16px;
    line-height: 1.2;
  }
}

@media screen and (max-width: 280px) {
  .banner {
    height: 600px;
  }
}
</style>